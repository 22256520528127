import { isNullish } from 'utils/common';
import { z } from 'zod';
import { ADOBE_JAVASCRIPT_LIBRARIES, ERROR_STATE_KEYS, ADOBE_JAVASCRIPT_LIBRARY_PROPERTY, ADOBE_OBJECT_PROPERTY_NAME, ADOBE_WEB_SDK_FUNCTION_PROPERTY_NAME } from './constants';

export const ERRORS = {
    NO_EQUAL_IDS: 'noEqualIds',
    NON_EMPTY: 'notEmpty',
    REQUIRED: 'required',
    NON_ZERO: 'nonZero',
    ADOBE_OBJECT_NAME_REQUIRED: 'adobeObjectRequired',
    GROUP_OR_TYPE: 'groupIdOrTypeId',
    DEFINED: 'defined',
    ADOBE_WEB_SDK_NAME_REQUIRED: 'adobeWebSdkRequired',
};

export const numericSelectItemSchema = z.object({
    label: z.string(),
    value: z.number(),
    selected: z.boolean().optional(),
});

const AdobeProtectionConfigBaseSchema = z.object({
    formError: z.never().optional(), //Only for error display
});

const adobeProtectionConfigTagsSchema = numericSelectItemSchema.array().min(1, ERRORS.REQUIRED);

const adobeProtectionConfigOptionalTagsBaseSchema = AdobeProtectionConfigBaseSchema.extend({ tags: adobeProtectionConfigTagsSchema });

const adobeProtectionConfigSelectedTagsSchema = adobeProtectionConfigTagsSchema.refine(tags => tags.some(tag => tag.selected), { message: ERRORS.REQUIRED });

const adobeProtectionConfigMandatoryTagsBaseSchema = AdobeProtectionConfigBaseSchema.extend({ tags: adobeProtectionConfigSelectedTagsSchema });

const nullishSchema = z.union([z.undefined({ invalid_type_error: ERRORS.DEFINED }), z.null({ invalid_type_error: ERRORS.DEFINED })]);

const adobeEVarSchema = z.number({ required_error: ERRORS.REQUIRED }).min(1, ERRORS.NON_ZERO);
const adobeInterfaceNameSchema = z.string({ required_error: ERRORS.REQUIRED, invalid_type_error: ERRORS.REQUIRED }).min(1, ERRORS.NON_EMPTY);

const adobeAnalyticsAppMeasurementsConfigBaseSchema = z.object({
    adobeObject: adobeInterfaceNameSchema,
    webSDKFunctionName: z.string().nullish(),
    triggerAdobeEvent: z.boolean().nullish(),
    [ADOBE_JAVASCRIPT_LIBRARY_PROPERTY]: z.literal(ADOBE_JAVASCRIPT_LIBRARIES.AppMeasurement),
});

const adobeAnalyticsWebSDKConfigBaseSchema = z.object({
    adobeObject: z.string().nullish(),
    webSDKFunctionName: adobeInterfaceNameSchema,
    triggerAdobeEvent: z.boolean().nullish(),
    [ADOBE_JAVASCRIPT_LIBRARY_PROPERTY]: z.literal(ADOBE_JAVASCRIPT_LIBRARIES.WebSDk),
});

const adobeAnalyticsAppMeasurementsConfigNullishThreatGroupSchema = adobeAnalyticsAppMeasurementsConfigBaseSchema.extend({
    threatTypeId: adobeEVarSchema,
    threatGroupId: adobeEVarSchema.nullish(),
});

const adobeAnalyticsWebSDKConfigNullishThreatGroupSchema = adobeAnalyticsWebSDKConfigBaseSchema.extend({
    threatTypeId: adobeEVarSchema,
    threatGroupId: adobeEVarSchema.nullish(),
});

const adobeAnalyticsAppMeasurementsConfigNullishThreatTypeSchema = adobeAnalyticsAppMeasurementsConfigBaseSchema.extend({
    threatTypeId: adobeEVarSchema.nullish(),
    threatGroupId: adobeEVarSchema,
});

const adobeAnalyticsWebSDKConfigNullishThreatTypeSchema = adobeAnalyticsWebSDKConfigBaseSchema.extend({
    threatTypeId: adobeEVarSchema.nullish(),
    threatGroupId: adobeEVarSchema,
});

export const adobeAnalyticsConfigNullishSchema = z.union([
    z.object({
        threatTypeId: nullishSchema,
        threatGroupId: nullishSchema,
        adobeObject: z.string().nullish(),
        webSDKFunctionName: nullishSchema,
        triggerAdobeEvent: z.literal<boolean>(false).nullish(),
        [ADOBE_JAVASCRIPT_LIBRARY_PROPERTY]: z.literal(ADOBE_JAVASCRIPT_LIBRARIES.WebSDk).nullish(),
    }),
    z.object({
        threatTypeId: nullishSchema,
        threatGroupId: nullishSchema,
        adobeObject: nullishSchema,
        webSDKFunctionName: z.string().nullish(),
        triggerAdobeEvent: z.literal<boolean>(false).nullish(),
        [ADOBE_JAVASCRIPT_LIBRARY_PROPERTY]: z.literal(ADOBE_JAVASCRIPT_LIBRARIES.AppMeasurement).nullish(),
    }),
]);

export const adobeAnalyticsNonEmptyConfigSchema = z.union([
    adobeAnalyticsAppMeasurementsConfigNullishThreatGroupSchema,
    adobeAnalyticsAppMeasurementsConfigNullishThreatTypeSchema,
    adobeAnalyticsWebSDKConfigNullishThreatTypeSchema,
    adobeAnalyticsWebSDKConfigNullishThreatGroupSchema,
]);

export const adobeAnalyticsConfigSchema = z.union([adobeAnalyticsNonEmptyConfigSchema, adobeAnalyticsConfigNullishSchema]);

export const adobeAnalyticsFormSchema = z.union([
    adobeProtectionConfigMandatoryTagsBaseSchema.extend({
        threatTypeId: nullishSchema,
        threatGroupId: nullishSchema,
        adobeObject: adobeInterfaceNameSchema,
        webSDKFunctionName: adobeInterfaceNameSchema.nullish(),
        triggerAdobeEvent: z.literal<boolean>(false).nullish(),
    }),
    adobeProtectionConfigMandatoryTagsBaseSchema.extend({
        threatTypeId: nullishSchema,
        threatGroupId: nullishSchema,
        adobeObject: adobeInterfaceNameSchema.nullish(),
        webSDKFunctionName: adobeInterfaceNameSchema,
        triggerAdobeEvent: z.literal<boolean>(false).nullish(),
    }),
    adobeProtectionConfigMandatoryTagsBaseSchema.and(adobeAnalyticsNonEmptyConfigSchema).superRefine(({ threatGroupId, threatTypeId }, ctx) => {
        if (!isNullish(threatGroupId) && !isNullish(threatTypeId) && threatGroupId === threatTypeId)
            [ERROR_STATE_KEYS.THREAT_TYPE, ERROR_STATE_KEYS.THREAT_GROUP, ERROR_STATE_KEYS.FORM].forEach(path =>
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: ERRORS.NO_EQUAL_IDS,
                    path: [path],
                })
            );
    }),
    adobeProtectionConfigOptionalTagsBaseSchema.and(adobeAnalyticsConfigNullishSchema).superRefine((_, ctx) => {
        if (ctx.path.some(item => item === ADOBE_OBJECT_PROPERTY_NAME || item === ADOBE_WEB_SDK_FUNCTION_PROPERTY_NAME))
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [ERROR_STATE_KEYS.FORM],
                message: ERRORS.GROUP_OR_TYPE,
            });
    }),
]);
