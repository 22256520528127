import stylesV1 from './LandingPage.module.scss';
import stylesV2 from './LandingPageV2.module.scss';
import HelpIcon from '@/assets/svgs/help-circle.svg';
import { CHEQ, Product } from './utils';
import { constants } from 'utils/split.io';
import { useSplit } from 'hooks';
interface IProps {
    cm: any;
    item: Product;
    onClick: (item: Product) => void;
    onClickContactUsHandler: (item: Product) => void;
}
const ProductElement = ({ cm, item, onClick, onClickContactUsHandler }: IProps) => {
    const isNewLandingPage = useSplit(constants.PARADOME_REPACKAGING);
    const styles = isNewLandingPage.isOn ? stylesV2 : stylesV1;
    return (
        <div className={`${styles.box} ${!item.enabled && styles.disabled}`} onClick={() => onClick(item)}>
            <div className={styles.top}>
                <img src={item.enabled ? item.icon : item.disabledIcon} alt="product-icon" />
                <div className={`${styles.label} ${!item.enabled && styles.disabled}`}>{`${cm.get(CHEQ)} ${cm.get(item.name)}`}</div>
                <div className={`${styles.description} ${!item.enabled && styles.disabled}`}>{cm.get(item.description)}</div>
            </div>
            {!item.enabled && (
                <div className={styles.learnMore}>
                    <img src={HelpIcon} alt="help" className={styles.help} />
                    {cm.get('LandingPage.LearnMore')}{' '}
                    <span onClick={() => onClickContactUsHandler(item)} className={styles.contactUs}>
                        {cm.get('ContactUs')}
                    </span>
                </div>
            )}
        </div>
    );
};
export default ProductElement;
