export const ADOBE_OBJECT_PROPERTY_NAME = 'adobeObject';
export const ADOBE_JAVASCRIPT_LIBRARY_PROPERTY = 'javascriptLibrary';
export const ADOBE_WEB_SDK_FUNCTION_PROPERTY_NAME = 'webSDKFunctionName';

export const ERROR_STATE_KEYS = {
    TRIGGER_EVENT: 'triggerAdobeEvent',
    THREAT_TYPE: 'threatTypeId',
    THREAT_GROUP: 'threatGroupId',
    ADOBE_OBJECT: ADOBE_OBJECT_PROPERTY_NAME,
    TAGS: 'tags',
    FORM: 'formError',
};

export const DEFAULT_ADOBE_OBJECT_NAME = 's';
export const DEFAULT_ADOBE_WEB_SDK_FUNCTION_NAME = 'alloy';

export const ADOBE_JAVASCRIPT_LIBRARIES = {
    AppMeasurement: 0,
    WebSDk: 1,
};
