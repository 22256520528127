import styles from './toggle.module.scss';
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

export type ToggleProps<T = string | number> = {
    label: string;
    isLoading?: boolean;
    disabled?: boolean;
    selected?: T;
    options: Array<{ value: T; label: string }>;
} & ToggleButtonGroupProps;

export const Toggle = ({ label, selected, disabled, onChange, options }: ToggleProps) => {
    return (
        <section className={styles.toggleWrapper}>
            <label>{label}</label>
            <ToggleButtonGroup value={selected} exclusive onChange={onChange} aria-label={label} className={styles.toggle}>
                {options.map(({ value, label }) => (
                    <ToggleButton
                        key={value}
                        value={value}
                        disabled={disabled}
                        style={
                            selected === value
                                ? {
                                      backgroundColor: '#ea3385',
                                  }
                                : undefined
                        }
                    >
                        {label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </section>
    );
};
