import { httpRequest, methods } from '../http';
/* @ts-ignore */
import config from '@config';
import { UserAPI } from 'components/containers/management/data-and-analytics/api-integration/Types';

const BASE_URL = config.cqIAMBaseAPI;
const TENANT_BASE_URL = `${config.cqIAMBaseAPI}/tenant`;

interface GetTenantUsersResponse {
    users: UserAPI[];
    count: number;
}

interface CreateTenantUserResponse {
    user: UserAPI;
}

class IAMService {
    static createUser = async (name: string, password: string, networkId: number) => {
        const { response }: { response: Promise<CreateTenantUserResponse> } = httpRequest({
            baseURL: `${TENANT_BASE_URL}/create_user`,
            method: methods.post,
            body: { name, password, networkId },
        });
        return (await response).user;
    };

    static getUsers = async (networkId: number): Promise<UserAPI[]> => {
        const { response }: { response: Promise<GetTenantUsersResponse> } = httpRequest({ baseURL: `${TENANT_BASE_URL}/users/${networkId}`, method: methods.get });
        return (await response).users;
    };

    static revokeUser = async (username: string, password: string) => {
        const { response } = httpRequest({ baseURL: `${TENANT_BASE_URL}/reset_password`, method: methods.post, body: { username, password } });
        await response;
    };

    static inviteUser = async (body: object) => {
        const { response } = httpRequest({ baseURL: `${BASE_URL}/user/invite`, method: methods.post, body: { ...body, send_email: true } });
        return await response;
    };

    static removeUserFromOrganization = (id: string, org_id: string, isPrivacyProduct: boolean) => {
        const { response } = httpRequest({ baseURL: BASE_URL, method: methods.delete, pathname: `/user/${id}/${org_id}?isPrivacyProduct=${isPrivacyProduct}` });
        return response;
    };

    static editUser = async (body: object) => {
        const { response } = httpRequest({ baseURL: BASE_URL, method: methods.post, pathname: `/user/edit`, body });
        return await response;
    };

    static handleUserStatus = async (body: object) => {
        const { response } = httpRequest({ baseURL: BASE_URL, method: methods.put, pathname: `/user/handleUserStatus`, body });
        return await response;
    };

    static editProfile = (body: object) => {
        const { response } = httpRequest({ baseURL: BASE_URL, method: methods.post, pathname: `/user/profile`, body });
        return response;
    };

    static getUsersInOrganization = (org_id: string, connectionName: string, sso: boolean) => {
        const { response } = httpRequest({
            baseURL: BASE_URL,
            method: methods.post,
            pathname: `/organization/members`,
            params: { org_id, sso },
            body: { connectionName },
        });
        return response;
    };

    static changePassword = () => {
        const { response } = httpRequest({ baseURL: BASE_URL, method: methods.get, pathname: `/user/profile/change_password` });
        return response;
    };

    static activateUser = async (id: string, email: string, username: string, combotagUserId: number, networkId: number, isPrivacyProduct: boolean) => {
        const { response } = httpRequest({ baseURL: `${BASE_URL}/user/activate`, method: methods.put, body: { id, email, username, combotagUserId, networkId, isPrivacyProduct } });
        return await response;
    };
    static getOrganizationByAuth0UserId = async (userId: string) => {
        const { response } = httpRequest({ baseURL: `${BASE_URL}/organizations/${userId}`, method: methods.get });
        return await response;
    };
}

export default IAMService;
